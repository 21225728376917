import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { ProductCardIcon } from "../components/ProductCardIcon"
import { Filter } from "../components/Filter"
import { FadeInWrapper } from "../components/FadeInWrapper"
import helpers from "../styles/helpers.module.css"
import advice from "../assets/icons/advice.svg"
import chempeel from "../assets/icons/chempeel.svg"
import consultation from "../assets/icons/consultation.svg"
import buttons from "../styles/buttons.module.css"

const Products = props => {
  const data = props.data.allFile.edges
  const productCategories = props.data.site.siteMetadata.productCategories
  const productsDestructured = []

  data.map(obj => {
    return productsDestructured.push(obj.node.childMarkdownRemark.frontmatter)
  })

  const [products, setProductsState] = useState(productsDestructured)

  return (
    <Layout page="Treatments">
      <SEO title="Treatments" />
      <div>
        <div className="container px-6 py-32 mx-auto sm:px-8 md:px-12 lg:px-6 md:py-40 lg:py-48 xl:py-56">
          <div className="xl:px-10">
            <p className={`${helpers.overline} mb-2`}>Treatments</p>
            <h1 className="mt-2 mb-12">
              <i>Non-surgical</i> cosmetic treatments using high quality,{" "}
              <i className="lowercase">medical grade</i> products.
            </h1>
            <Link
              className={`${buttons.btnPrimary} inline-block md:inline`}
              to="/contact"
            >
              Book a consultation
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container px-6 py-8 mx-auto sm:px-8 md:px-12 lg:px-6 md:py-16">
          <div className="xl:px-10">
            <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-6">
              <div className="col-span-1 mb-6">
                <Filter
                  types={productCategories}
                  items={productsDestructured}
                  setItemsState={setProductsState}
                  productsLength={products.length}
                />
              </div>
              <div className="col-span-3">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {products.map(({ title, path, excerpt, image }, i) => (
                    <ProductCardIcon
                      key={`product-card-image-${i}`}
                      title={title}
                      path={path}
                      excerpt={excerpt}
                      image={image}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container px-6 mx-auto sm:px-8 md:px-12 lg:px-6">
          <div className="px-4">
            <FadeInWrapper>
              <div className="py-32">
                <h2 className="mb-8 text-center">How it works</h2>
                <div className="flex grid items-center justify-center lg:grid-cols-3">
                  <div className="px-5 py-8">
                    <div className="relative pl-6 -mb-12">
                      <div className="font-bold leading-none text-mainBrand text-10xl">
                        1
                      </div>
                      <img
                        className="absolute left-0 h-10 mb-4 bottom-1 top-12"
                        src={consultation}
                        alt="Doctor's clipboard icon"
                      />
                    </div>
                    <div className="relative z-20 mt-4">
                      <h5 className="font-semibold">Consultation</h5>
                      <p>
                        First, a discussion of your goals and any previous
                        treatments. Dr Yalda will then recommend the right
                        treatment and plan for you.
                      </p>
                    </div>
                  </div>
                  <div className="px-5 pt-10 pb-16">
                    <div className="relative pl-6 -mb-12">
                      <div className="font-bold leading-none text-mainBrand text-10xl">
                        2
                      </div>
                      <img
                        className="absolute left-0 h-10 mb-4 bottom-1 top-12"
                        src={chempeel}
                        alt="Doctor's clipboard icon"
                      />
                    </div>
                    <div className="relative z-20 mt-4">
                      <h5 className="font-semibold">Treatment</h5>
                      <p>
                        Most treatments take about 10 minutes, and are not
                        painful because of the minimally invasive nature of
                        non-surgical cosmetic treatments.
                      </p>
                    </div>
                  </div>
                  <div className="px-5 pt-10 pb-16">
                    <div className="relative pl-6 -mb-12">
                      <div className="font-bold leading-none text-mainBrand text-10xl">
                        3
                      </div>
                      <img
                        className="absolute left-0 h-10 mb-4 bottom-1 top-12"
                        src={advice}
                        alt="Doctor's clipboard icon"
                      />
                    </div>
                    <div className="relative z-20 mt-4">
                      <h5 className="font-semibold">Aftercare and advice</h5>
                      <p>
                        You will be briefed about aftercare in your
                        consultation. Dr Yalda will be on hand around the clock
                        for any follow-up questions you might have.
                      </p>
                    </div>
                  </div>
                  <Link
                    className={`${buttons.btnPrimary} block mb-4 md:mb-6 xl:mr-4 xl:mb-0 xl:inline-block lg:col-start-2`}
                    to="/contact"
                  >
                    Book a consultation
                  </Link>
                </div>
              </div>
            </FadeInWrapper>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Products

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "products" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              id
              layout
              title
              category
              path
              excerpt
              image
              imageLarge
              concerns
              area
              productAttributeTitleOne
              productAttributeTitleTwo
              productAttributeTitleThree
              productAttributeTitleFour
              productAttributeValueOne
              productAttributeValueTwo
              productAttributeValueThree
              productAttributeValueFour
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        productCategories {
          title
          uid
        }
      }
    }
  }
`
